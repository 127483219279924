import React from "react";
import Slideee1 from "../assets/Group1.jpeg";
import Slideee2 from "../assets/Group2.jpeg";
import Slideee3 from "../assets/Group3.jpeg";
import Slideee4 from "../assets/Group4.jpeg";
import Slideee5 from "../assets/banner5.jpg";
import Slideee6 from "../assets/banner2.jpeg";
import Slideee7 from "../assets/banner6.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeSlider = () => {
  return (
    <div className="w-[85vw] md:w-full shadow-HomeShadow bg-transparent  lg:h-[87vh]">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee7}
              loading="lazy"
              alt={Slideee2.alt}
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee6}
              loading="lazy"
              alt={Slideee2.alt}
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee5}
              loading="lazy"
              alt={Slideee2.alt}
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee2}
              loading="lazy"
              alt={Slideee2.alt}
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee3}
              loading="lazy"
              alt=""
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee4}
              loading="lazy"
              alt=""
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-[85vw] md:w-[92vw] lg:w-full h-full">
            <LazyLoadImage
              src={Slideee1}
              loading="lazy"
              alt=""
              className="object-fill w-full h-full"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeSlider;
