import React from "react";
import HomeContant from "./HomeContant";
import Img1 from "../assets/content2.png";
import Img2 from "../assets/bannersite2.jpeg";
import Img3 from "../assets/bannersite.jpeg";
import { t } from "i18next";
const ContentContainer = () => {
  const data1 = {
    name: t("Hobs"),
    title: t("GAS and INDUCTION"),
    content:
      t("At Nardi, we take pride in designing our hobs to meet your every need, no matter how specific. We create tailor-made solutions with a focus on high quality and aesthetic appeal, ensuring that every detail is taken care of. Our ability to customize combines style, innovation, and functionality to create a diverse range of quality solutions that cater to your unique requirements."),
    img: Img1,
  };
  const data2 = {
    name: t("Hoods"),
    title: t("CLASSIC or MODERN"),
    content:
      t("Whether you prefer a suspended or built-in style, modern or classic design, or a professional-grade appliance, choosing the right kitchen hood is essential for both functionality and aesthetics. At Nardi, our hoods are designed to meet all of these needs, while also ensuring ease of use and installation. Our hoods seamlessly integrate with your unique kitchen style, providing the perfect balance of form and function."),
    img: Img2,
  };
  const data3 = {
    name: t("Other Products"),
    title: t("other nardi solutions"),
    content:
      t("At Nardi, we offer a wide range of kitchen furnishings to meet all of your needs. In addition to our standard products, we also offer a series of exclusive items to help you customize your kitchen and create unique experiences. Our Dolce Vita line features a selection of premium products, including a microwave oven, a coffee machine, and a blast chiller."),
    img: Img3,
  };
  return (
    <div className=" flex flex-col lg:flex-row items-center justify-center">
      <HomeContant data={data1} />
      <HomeContant data={data2} reverse={true} />
      <HomeContant data={data3} />
    </div>
  );
};

export default ContentContainer;
